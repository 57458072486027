import React, {useRef} from 'react';
import PopUp from "../global/PopUp";
import DeleteNewsForm from "../form/DeleteNewsForm";
import EditNewsForm from "../form/EditNewsForm";

function NewsProp (props) {

    const popUpDel = useRef();
    const popUpEdit = useRef();

    return(
        <div className="mb-10">
            <PopUp ref={popUpDel} children={<DeleteNewsForm id={props.news.id} sended={() => {popUpDel.current.closePopup();}} />}/>
            <PopUp ref={popUpEdit} children={<EditNewsForm news={props.news} sended={() => {popUpEdit.current.closePopup();}} />}/>
            {localStorage.getItem('username') !== null &&
                <div className={"z-10 absolute flex space-x-2 m-2"}>
                    <div className={"bg-red-500 px-1 text-white rounded-full font-extrabold cursor-pointer"}
                         onClick={() => popUpDel.current.seePopup()}>SUPPRIMER</div>
                    <div className={"bg-orange-500 px-1 text-white rounded-full font-extrabold cursor-pointer"}
                         onClick={() => popUpEdit.current.seePopup()}>MODIFIER</div>
                </div>
            }
            <a href={'actus/' + props.news.url}>
                <div className="relative h-40">
                    <img src={"/content" + props.news.minia} alt={"Bannière de l'article " + props.news.title} className="w-full h-full object-cover bg-flavigny/80" />
                    <div className="-bottom-5 right-0 bg-green-500 p-2 absolute">
                        <span className="text-white font-bold font-outfit">
                            {new Date(props.news.date).toLocaleDateString('fr-FR')}
                        </span>
                    </div>
                    { props.news.date !== null && new Date(props.news.date) > new Date() - 15*24*60*60*1000 &&
                        <div className={"absolute left-0 top-0 -rotate-12 -translate-x-1/4 -translate-y-1/3 whitespace-nowrap rounded-full bg-green-300 px-2.5 py-0.5 text-sm text-green-800"}>
                            Nouveau !
                        </div>
                    }
                </div>
                <div className="mt-8 space-y-4">
                    <p className="font-bold font-outfit text-2xl">
                        {props.news.title.toUpperCase()}
                    </p>
                    <p 
                        dangerouslySetInnerHTML={{__html: props.news.content.split('.')[0] + '.'}}
                        className="text-justify text-sm text-[#626f8c]"></p>
                </div>
            </a>
        </div>
    );
}

export default NewsProp;
